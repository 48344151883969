import React, { useEffect, useRef, useState }    from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { Row, Col, Image, Segmented, Card, Statistic, Button, Affix } from 'antd'
import { ArrowDownOutlined, ArrowUpOutlined, WechatOutlined } from '@ant-design/icons';

import coworkData from '../../data/cowork.json'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';



// import required modules
import { FreeMode, Navigation, Thumbs, Autoplay } from 'swiper/modules';


export default function CoworkDeatil({ center }) { 

    window.scrollTo(0,0)

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const kjpt = ["AIOT办公系统", "无线投屏", "人脸识别服务", "机器人配送", "无接触式管家服务", "AR/VR游戏区", "活力吧台","咖啡茶点"]
    const fwpt = ["IT网络定制", "行政后勤服务", "人力资源服务", "财务管理服务", "法律服务咨询", "网络安全服务", "投资融资服务", "设计文案服务", "兴趣活动交流","工商代办服务"]

    const urlParams = useParams()
    //console.log(urlParams)
    const navigate = useNavigate()
    var detail = {}

    if (urlParams.id <= 7) {
        for (let i = 0; i < coworkData.length; i++) {
            //console.log(urlParams.id == coworkData[i].id)
            if (urlParams.id == coworkData[i].id) {
                //console.log(coworkData[i])
                detail = coworkData[i]
            }

        }
    } else { 
        console.log("jhahahhhah")
        navigate("/")
        return;
    }
    


    return (
        <Row justify={'center'} gutter={10} style={{padding:'20px '}}>
            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                <Row gutter={20}>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        <Swiper
                            style={{
                                '--swiper-navigation-color': '#fff',
                                '--swiper-pagination-color': '#fff',
                            }}
                            spaceBetween={0}
                            navigation={true}
                            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null, }}
                            modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                            effect={'fade'}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            className="mySwiper2"
                        >
                            {detail.pic.map((item) => {
                                return (
                                    <SwiperSlide>
                                        <Image style={{ width: '100%', borderRadius: "6px" }} preview={false} src={require('../../images/cowork/' + detail.nickname + '/' + item + '.jpg')} />
                                    </SwiperSlide>
                                )
                            })}
                            
                        </Swiper>
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            spaceBetween={5}
                            slidesPerView={4}
                      
                            freeMode={true}
                            watchSlidesProgress={true}
                            modules={[FreeMode, Navigation, Thumbs]}
                            className="mySwiper"
                        >
                            {detail.pic.map((item) => { 
                                return (
                                    <SwiperSlide>
                                        <Image style={{ width: '100%',borderRadius:"6px" }} preview={false} src={require('../../images/cowork/' + detail.nickname + '/' + item +'.jpg')} />
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </Col>
                    <Col span={24} className="detail_mobile_screen">
                        <Card bordered={false} style={{ background: '#f0f3fa' }}>
                            <Row style={{ textAlign: 'center' }}>
                                <Col span={24} style={{ marginBottom: "18px" }}>
                                    <h1 style={{ fontSize: '18px' }}>
                                        {detail.name}
                                    </h1>
                                    <div>
                                        {detail.city}{detail.location}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12} style={{ paddingBottom: '20px' }}>
                                    <Statistic
                                        title="总工位数"
                                        value={detail.desk}
                                        precision={0}
                                        valueStyle={{ color: '#000', fontSize: '18px' }}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Col>
                                <Col span={12} style={{ paddingBottom: '20px' }}>
                                    <Statistic
                                        title="会议室"
                                        value={detail.meetingroom}
                                        precision={0}
                                        valueStyle={{ color: '#000', fontSize: '18px' }}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Col>
                                <Col span={12} style={{ paddingBottom: '20px' }}>
                                    <Statistic
                                        title="高速网络"
                                        value={'有'}
                                        precision={0}
                                        valueStyle={{ color: '#000', fontSize: '18px' }}
                                        prefix={''}
                                        suffix=""
                                    />
                                </Col>
                                <Col span={12} style={{ paddingBottom: '20px' }}>
                                    <Statistic
                                        title="共享打印机"
                                        value={'有'}
                                        precision={2}
                                        valueStyle={{ color: '#000', fontSize: '18px' }}
                                        prefix={''}
                                        suffix=""
                                    />
                                </Col>
                                {
                                    detail.product.map((item) => {
                                        return (
                                            <Col span={12} style={{ paddingBottom: '20px' }}>
                                                <Statistic
                                                    title={item.name}
                                                    value={item.price}
                                                    precision={0}
                                                    valueStyle={{ color: 'red', fontSize: '18px' }}
                                                    prefix={''}
                                                    suffix={<div style={{ fontSize: '12px' }}>元/个/月 起</div>}
                                                />
                                            </Col>
                                        )
                                    })
                                }

                            </Row>

                            <Row style={{ textAlign: 'center', padding: '30px 0' }}>
                                <Col span={24}>
                                    <a href={detail.wechat} style={{ color: '#000' }} target="_blank" rel="noopener noreferrer">

                                        <Button type="primary" size="large" style={{ width: '95%' }}>
                                            <WechatOutlined style={{ fontSize: '20px', color: '#fff', position: 'relative', top: '2px' }} />
                                            立即联系社区
                                        </Button>
                                    </a>
                                </Col>  
                            </Row>
                        </Card>
                    </Col>
                    <Col className='detail_big_screen' span={6}>
                        <Affix offsetTop={80}>
                        <Card bordered={false} style={{ background: '#f0f3fa' }}>
                                <Row style={{textAlign:'center'}}>
                                    <Col span={24} style={{marginBottom:"18px"}}>
                                    <h1 style={{fontSize:'18px'}}>
                                        {detail.name}
                                    </h1>
                                    <div>
                                        {detail.city}{detail.location}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12} style={{ paddingBottom: '20px' }}>
                                    <Statistic
                                        title="总工位数"
                                        value={detail.desk}
                                        precision={0}
                                        valueStyle={{ color: '#000', fontSize: '18px' }}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Col>
                                <Col span={12} style={{ paddingBottom: '20px' }}>
                                    <Statistic
                                        title="会议室"
                                        value={detail.meetingroom}
                                        precision={0}
                                        valueStyle={{ color: '#000', fontSize: '18px' }}
                                        prefix={''}
                                        suffix="个"
                                    />
                                </Col>
                                <Col span={12} style={{ paddingBottom: '20px' }}>
                                    <Statistic
                                        title="高速网络"
                                        value={'有'}
                                        precision={0}
                                        valueStyle={{ color: '#000', fontSize: '18px' }}
                                        prefix={''}
                                        suffix=""
                                    />
                                </Col>
                                <Col span={12} style={{ paddingBottom: '20px' }}>
                                    <Statistic
                                        title="共享打印机"
                                        value={'有'}
                                        precision={2}
                                        valueStyle={{ color: '#000', fontSize: '18px' }}
                                        prefix={''}
                                        suffix=""
                                    />
                                </Col>
                                {
                                    detail.product.map((item) => { 
                                        return (
                                            <Col span={12} style={{ paddingBottom: '20px' }}>
                                                <Statistic
                                                    title={item.name}
                                                    value={item.price}
                                                    precision={0}
                                                    valueStyle={{ color: 'red', fontSize: '18px' }}
                                                    prefix={''}
                                                    suffix={<div style={{fontSize:'12px'}}>元/个/月 起</div>}
                                                />
                                            </Col>
                                        )
                                    })
                                }
                                
                            </Row>

                            <Row style={{ textAlign: 'center', padding: '30px 0' }}>
                                    <Col span={24}>
                                        <a href={detail.wechat} style={{ color: '#000' }} target="_blank" rel="noopener noreferrer">

                                            <Button type="primary" size="large" style={{width:'95%'}}>
                                        <WechatOutlined style={{ fontSize: '20px', color: '#fff',position:'relative',top:'2px' }} />
                                        立即联系社区
                                            </Button>
                                            </a>
                                </Col>        
                            </Row>
                            </Card>
                        </Affix>

                    </Col>
                </Row>

                <Row justify={'center'}>
                    <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                        
                        <h1>
                            {detail.name}
                        </h1>
                        
                    </Col>
            
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        <Segmented block options={['项目介绍', '地址交通', '空间配套', '服务配套']} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        <h3 style={{fontSize:"20px",fontWeight:'bold'}}>
                            项目介绍
                        </h3>
                        <div style={{fontSize:'15px',lineHeight:'24px'}}>
                            {detail.description}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        <h3 style={{ fontSize: "20px", fontWeight: 'bold' }}>
                            地址交通
                        </h3>
                        <div style={{ fontSize: '15px', lineHeight: '24px' }}>
                            <div>
                                {detail.city}{detail.location}
                            </div>
                            <div>
                                { detail.subway}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        <h3 style={{ fontSize: "20px", fontWeight: 'bold' }}>
                            空间配套
                        </h3>
                        <Row>
                            {
                                kjpt.map((item,index) => {
                                    return (
                                        <Col span={6} style={{textAlign:'center',padding:'20px 0'}}>
                                            <img style={{width:'50px'}} src={ require('../../images/cowork/kjpt/' + (index + 1) + '.png')} />
                                            <div>
                                                {item}
                                            </div>
                                        </Col> 
                                    )
                                 })
                            }
                            
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        <h3 style={{ fontSize: "20px", fontWeight: 'bold' }}>
                            服务配套
                        </h3>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        
                        <Row>
                            {
                                fwpt.map((item, index) => {
                                    return (
                                        <Col span={6} style={{ textAlign: 'center', padding: '20px 0' }}>
                                            <img style={{ width: '50px' }} src={require('../../images/cowork/fwpt/' + (index + 1) + '.png')} />
                                            <div>
                                                {item}
                                            </div>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row> 
    )
}