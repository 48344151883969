
import { useState, useEffect } from 'react'
import { Row, Col, Card } from 'antd'
import './styles/efficiency.css';

import Snowfall from 'react-snowfall'
import img1 from '../images/snow.png'
import img2 from '../images/snow.png'


export default function Values() {

    const bada = [
        {
            img: '1.png',
            name: "资产管理"
        },
        {
            img: '2.png',
            name: "招商管理"
        },
        {
            img: '3.png',
            name: "物业管理"
        },
        {
            img: '4.png',
            name: "运营管理"
        },
        {
            img: '5.png',
            name: "风控管理"
        },
        {
            img: '6.png',
            name: "报表决策"
        },
        {
            img: '7.png',
            name: "智能硬件"
        },
        {
            img: '8.png',
            name: "商业社交"
        }

    ]


    const snowflake1 = document.createElement('img')
    snowflake1.src = img1
    const snowflake2 = document.createElement('img')
    snowflake2.src = img2

    const images = [snowflake1, snowflake2]


    return (

        <>

            <Snowfall
                style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                }}
                images={images}
                radius={[2.5, 13.0]}
            />

            <Row gutter={24} style={{ marginTop: '50px' }}>
                <Col style={{ textAlign: 'center', fontSize: '25px', marginBottom: '30px' }} span={24}>
                    解决资产管理痛点
                </Col>
            </Row>
            <Row style={{ marginBottom: '50px' }} gutter={24}>
                <Col span={20} offset={2}>
                    <Row gutter={24}>
                        <Col offset={4} className="icon-box" span={6}>
                            <span className="home-icon-1 home-icon"></span>
                            <div>
                                信息化程度低
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-2 home-icon"></span>
                            <div>
                                管理不到位
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-3 home-icon"></span>
                            <div>
                                运营成本高
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '40px' }} gutter={24}>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-4 home-icon"></span>
                            <div>
                                营销效率低
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-5 home-icon"></span>
                            <div>
                                风控滞后
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-6 home-icon"></span>
                            <div>
                                决策信息不准确
                            </div>
                        </Col>
                        <Col className="icon-box" span={6}>
                            <span className="home-icon-7 home-icon"></span>
                            <div>
                                客户关系疏远
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col style={{ textAlign: 'center', fontSize: "25px", margin: "60px 0 40px 0" }} span={24}>
                    BeingCospace品质管理八大核心价值
                </Col>
            </Row>

            <Row gutter={24}>
                <Col offset={2} span={20}>
                    <Row gutter={24}>
                        {
                            bada.map((item, key) => {
                                return (
                                    <Col style={{ marginBottom: '35px' }} span={6}>
                                        <Card className="bada-box" cover={
                                            <img
                                                alt={item.name}
                                                src={require('../images/bada/' + item.img)}
                                            />
                                        } bordered={false}>
                                            {item.name}
                                        </Card>
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </Col>

            </Row>

        </>
    )

}