import { Row, Col, Image, Skeleton, Button,Tag } from "antd";
import './styles/livingplus.css'
export default function LivingPlus(params) {
    return (
        <>
            <Row className="slogan_bg" justify={'center'}>
                <Col span={24}>
                    <h1 className="logo_animate">
                        LivingPlus
                    </h1>
                    
                </Col>
                <Col span={18} style={{ position: 'absolute',top:'70%' }}>
                    <Button type="default" size="large" style={{width:'200px',padding:'10px 0',height:'50px',borderRadius:"4px",border:"none",marginRight:"10px"}}>2024 敬请期待</Button>
                    {/* <Button type="default" size="large" style={{ width: '200px', padding: '10px 0', height: '50px', borderRadius: "4px", border: "none" }}>长租公寓</Button> */}
                </Col>
            </Row>

            <Row justify={'center'}>
                <Col span={18} style={{ fontSize: '30px', textAlign: 'center', fontFamily: 'siyuan', padding: '30px 0' }}>
                    全新业务模式，融合BeingCospace主营业务
                </Col>
                <Col span={18} style={{ fontSize: '18px', textAlign: 'center', fontFamily: 'siyuan'}}>
                    商业 + 办公+公寓，减少通勤一小时
                </Col>
                <Col span={18} style={{marginTop:'80px'}}>
                    <Row gutter={ 10} style={{height:'180px',overflow:"hidden"}}>
                        <Col span={8}>
                            <img style={{ width: '100%' }} src={require('../images/apartment/pics/6.png')} />
                        </Col>
                        <Col span={8}>
                            <img style={{ width: '100%' }} src={require('../images/apartment/pics/3.png')} />
                        </Col>
     
                        <Col span={8}>
                            <img style={{ width: '100%' }} src={require('../images/apartment/pics/5.png')} />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row gutter={'24'} justify={'center'} className="points">
                <Col span={6} style={{textAlign:"left"}}>
                    
                    <div style={{ fontSize: '30px', textAlign: 'left', fontFamily: 'siyuan' }}>
                        有格调的现代设计
                    </div>
                    <div style={{textAlign:'left',color:"#888"}}>
                        xxxx不止是一个住所，志同道合的人们在这里分享空间、想法和对生活的热情。有趣味、有质量的社区活动将居民们联结起来，成就了一个充满多元与活力的社区。
                    </div>
                </Col>
                <Col span={12}>
                    
                    {/* <Row>
                        <Col span={12}>
                            <img style={{width:'100%'}} src={ require('../images/apartment/chufang.png')} />
                        </Col>
                    </Row> */}
                    <img style={{width:'100%'}} src={ require('../images/apartment/1.png')} />
                </Col>
            </Row>

            <Row gutter={'24'} justify={'center'} className="points">
                <Col span={6}>
                    <div style={{fontSize:'30px',textAlign:'left',fontFamily:'siyuan'}}>
                        办公&生活的自由结合
                    </div>
                    <div style={{ textAlign: 'left', color: '#333', fontFamily: 'siyuan' }}>
                        容入BeingCospace办公设计经验，独创办公娱乐公区，激发您的创意灵感。
                    </div>
                </Col>
                <Col span={12}>
                    <img style={{ width: '100%' }} src={require('../images/apartment/2.png')} />
                </Col>
            </Row>

            <Row gutter={'24'} justify={'center'} className="points">
                <Col span={6}>
                    <div style={{ fontSize: '30px', textAlign: 'left', fontFamily: 'siyuan' }}>
                        共享会议室
                    </div>
                    <div style={{ textAlign: 'left', color: '#333', fontFamily: 'siyuan' }}>
                        高品质BeingCospace Meeting Pro办公室，配备85英寸智能会议白板，刚刚好可坐6个人。雾化玻璃隔墙。。。。
                    </div>
                </Col>
                <Col span={12}>
                    <img style={{ width: '100%' }} src={require('../images/apartment/3.png')} />
                </Col>
            </Row>


            <Row gutter={'24'} justify={'center'} className="points">
                <Col span={6}>
                    <div style={{ fontSize: '30px', textAlign: 'left', fontFamily: 'siyuan' }}>
                        共享厨房
                    </div>
                    <div style={{ textAlign: 'left', color: '#333', fontFamily: 'siyuan' }}>
                        定期邀请星级主厨或者灵巧的住户，开展厨艺活动。同时在夜晚，厨房变身最In的酒吧，意味着你可以随时下楼去城市中最in的餐厅或酒吧喝一杯。
                    </div>
                </Col>
                <Col span={12}>
                    <img style={{ width: '100%' }} src={require('../images/apartment/chufang.png')} />
                    {/* <img style={{ width: '100%' }} src={require('../images/apartment/4.png')} /> */}
                </Col>
            </Row>

            <Row gutter={'24'} justify={'center'} className="points">
                <Col span={6}>
                    <div style={{ fontSize: '30px', textAlign: 'left', fontFamily: 'siyuan' }}>
                        共享洗衣房
                    </div>
                    <div style={{ textAlign: 'left', color: '#333', fontFamily: 'siyuan' }}>
                        定期邀请星级主厨或者灵巧的住户，开展厨艺活动。同时在夜晚，厨房变身最In的酒吧，意味着你可以随时下楼去城市中最in的餐厅或酒吧喝一杯。
                    </div>
                </Col>
                <Col span={12}>
                    <img style={{ width: '100%' }} src={require('../images/apartment/xiyi.png')} />
                    {/* <img style={{ width: '100%' }} src={require('../images/apartment/4.png')} /> */}
                </Col>
            </Row>

            <Row>
                <Col>
                    <img style={{width:"100%"}} src={ require('../images/apartment/pics/bottom.jpg')} />
                </Col>
           </Row>
            

            {/* <Row justify={'center'} className="points">
                <Col span={6}>
                    舒心入驻
                </Col>
                <Col span={6}>
                    安全健康
                </Col>
                <Col span={6}>
                    节能减排
                </Col>
            </Row> */}
        </>
    )
}