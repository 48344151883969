import { Row, Col, Card } from 'antd'
import './styles/tea.css'

export default function TianziTea() {
    return (
        <div style={{ background:'linear-gradient(rgb(241 245 246), rgb(255, 255, 255))'}}>
            <Row gutter={24} justify={'center'} align={'middle'} style={{padding:'80px 0'}}>
                <Col span={3} style={{textAlign:'center'}}>
                    <img style={{width:"150px"}} src={ require("../images/logo.png")} />
                </Col>
                <Col style={{ textAlign: 'center' }}>
                    <img style={{width:'35px'}} src={require('../images/tianzi/24gl-cross.png')} />
                </Col>
                <Col style={{ textAlign: 'center' }}>
                    <img style={{ width: "150px" }} src={ require("../images/tianzi/logo.png")} />
                </Col>
            </Row>
            <Row gutter={24} justify={'center'} align={'middle'} style={{marginBottom:'100px'}}>
                <Col span={8} style={{fontSize:'18px',color:'#333333',textAlign:'center'}}>
                    茶香四溢，思维更灵动！超级会议室现已变身为茶室，与天籽茶山共同营造惬意办公环境。在宁静的茶香中，激发无限创意，让工作与休闲和谐相融。来BeingCospace，体验不一样的办公新风尚。
                </Col>
            </Row>
            <Row gutter={18} justify={'center'} align={'middle'} >
                <Col span={24} style={{textAlign:'center',fontSize:'18px',marginBottom:"20px"}}>
                    以下社区已经上架天籽茶室
                </Col>
                <Col span={6}>
                    <Card
                        hoverable
                        style={{ width: '100%' }}
                        cover={<img alt="example" src={require('../images/tianzi/688.jpg')} />}
                    >
                        <Card.Meta title="上海688社区.天籽联名茶室" description="专享季节专款茶叶 >> 立即预约体验" />
                    </Card>
                </Col>
                
                <Col span={6}>
                    <Card
                        hoverable
                        style={{ width: '100%' }}
                        cover={<img alt="example" src={require('../images/tianzi/xy.jpg')} />}
                    >
                        <Card.Meta title="上海星扬社区.天籽联名茶室" description="专享季节专款茶叶 >> 立即预约体验" />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        hoverable
                        style={{ width: '100%' }}
                        cover={<img alt="example" src={require('../images/tianzi/688.jpg')} />}
                    >
                        <Card.Meta title="上海港汇社区.天籽联名茶室" description="专享季节专款茶叶 >> 立即预约体验" />
                    </Card>
                </Col>
            </Row>
            <Row className='pointer-box' gutter={24} justify={'center'} align={'top'} style={{ position: 'relative',margin:"100px 0" }}>
                <Col className='pointer' span={6}>
                    <div>
                        布朗山原始森林的独家产区
                    </div>
                    <div>
                        天籽·秘境来自布朗山独家产区，茶树藏于雨林群落深处，人迹罕至，神秘纯净，其间茶树树龄多为百年以上，完整生态链，原生物种格外丰富。
                    </div>
                </Col>
                <Col className='pointer' span={6}>
                    <div>
                        爱伲族古法工艺
                    </div>
                    <div>
                        秘境古茶林边缘空气洁净之地，也是天籽自属用地之上建设专属制茶寮。传承并深研普洱茶传统制茶工艺，以原生态的方式种茶、养茶，采茶、制茶。竹篾簸箕萎凋晒青，杂木当柴，手工匠心揉捻。让茶叶在制作全程保持和山野、树木，优质菌群持续的能量交融。
                    </div>
                </Col>
                <Col className='pointer' span={6}>
                    <div>
                        欧盟有机认证
                    </div>
                    <div>
                        天籽·秘境产区连续十年通过欧盟有机认证，每年两次欧盟有机检测人员实地调研，500余项有机考核标准全部通过。十几年如一日，爱护古茶林生态，不仅有机，可供随时溯源。
                    </div>
                </Col>
                {/* <Col span={18} style={{position:'absolute',top:'0'}}>
                    <img style={{width:'100%',borderRadius:'15px'}} src={require('../images/tianzi/bca4bf947eed0f3d8e74a7f2046ee0a8.jpeg')} />
                </Col> */}
            </Row>
            <Row gutter={24} justify={'center'} align={'middle'}>
                <Col span={6}>
                    <img style={{width:'100%',borderRadius:"30px"}} src={require('../images/tianzi/6dc973c9433d91119d4dfd73596aa28c.png')} />
                </Col>
                <Col span={6}>
                    <img style={{ width: '100%', borderRadius: "30px" }} src={require('../images/tianzi/bcfed66078c14f578cda1295534c78f9.png')} />
                </Col>
                <Col span={6}>
                    <img style={{ width: '100%', borderRadius: "30px" }} src={require('../images/tianzi/e3027fad92fb91fde1cef886f4ade246.png')} />
                </Col>
           </Row>
            <Row gutter={24} justify={'center'} align={'middle'} style={{marginTop:'100px'}}>
                <Col span={18}>
                    <img style={{width:'100%',borderRadius:'30px'}} src={require('../images/tianzi/1fc5681368886a2fe82da0b0776bd369.png')} />
                </Col>
            </Row>
        </div>
    )
}