import { Row,Col,Button,Result } from "antd"

export default function Print() { 

    return (
        <Result
            status="warning"
            title="打印机必须连接社区网络才可以使用"
            extra={
                <Row justify="center" gutter={10} style={{margin:'80px 0'}}>
                    <Col span={5} style={{ border: "1px solid #eee", padding:'20px 30px',borderRadius:"20px"}} className='print_step_box'>
                        <img src="https://framerusercontent.com/images/pizOKfpzaYrbaaZH0CzxsdZLc.svg" />
                        <div style={{marginTop:'20px'}}>
                            首先，确保您的设备连接到社区网络。如果您的设备之前连接了VPN，请确保已关闭VPN，以便能够正常连接到社区网络。
                            注意： 社区网络的SSID和密码通常会在办公空间的显眼位置贴出，或者您可以咨询社区管理人员获取相关信息。
                        </div>
                    </Col>
                    <Col span={5} style={{ border: "1px solid #eee", padding: '20px 20px', borderRadius: "20px",margin:'0 10px' }} className='print_step_box'>
                        <img src="https://framerusercontent.com/images/L8dIMWh6841HwcMuuwTYH1xUkI.svg" />
                        <div style={{ marginTop: '20px' }}>
                            在您的设备浏览器中输入网址print.beingcospace.com，并按下回车键。这将打开打印机的网页界面。您所在公司的打印机账号密码已经在您入驻的时候，由社区管理人员发送给您公司的管理人员，请联系您公司管理人员索要。
                        </div>    
                    </Col>
                    <Col span={5} style={{ border: "1px solid #eee", padding: '20px 20px', borderRadius: "20px" }} className='print_step_box'>
                        <img src="https://framerusercontent.com/images/4m6sQ1xqtDFl9JSmrp4pa7em28.svg" />
                        <div style={{ marginTop: '20px' }}>
                            在打开的网页界面中，您会找到上传文件的选项。点击上传按钮，从您的设备中选择要打印的文件。一旦文件上传完成，您可以点击开始打印按钮。打印机将开始处理您的打印任务。
                            注意： 在上传文件前，请确保文件格式是打印机支持的，并且文件已经完全准备好进行打印。如果有任何疑问或问题，建议您先查看打印机的使用手册或咨询社区管理人员。
                        </div>
                    </Col>
                </Row>
            }
        />
    )
}