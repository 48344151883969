import { useState,useEffect} from 'react'
import { Row, Col } from 'antd'
import axios from 'axios'
import React from 'react'



export default function Marketing() { 

    useEffect(() => {
        // axios.get('http://localhost:8100/hello', {
        //     community_id: '51'
        // })
        // axios.post('http://localhost:8100/getCommunityInfo', {
            
        // }, {
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // })

        axios.post('http://localhost:8100/getCommunityInfo', {
            community_id: '51'
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
        
    },[])
    

    return (
        <Row gutter={24} justify={'center'}>
            <Col span={18}>
                <h1>Marketing1</h1>
            </Col>
        </Row>
    );
}
