import { Button, Result } from 'antd';
import { useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
export default function ErrorPage(params) {

    const navigate = useNavigate()

    function goHome(params) { 
        //navigate("/home")
        window.location.href="https://www.beingcospace.com"
    }

    useEffect(() => {
      setTimeout(() => {
        goHome()
      }, 2000);
        
    })
    


    return (
        <div>
            <Result
                status="404"
                title="404"
                subTitle="抱歉，您访问的页面不存在，即将带您回到首页"
                extra={<Button onClick={goHome} type="primary">返回首页</Button>}
            />
        </div>
    )
}