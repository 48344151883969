import { Row, Col, Card, Divider, Tag } from 'antd';
import '../components/styles/contact.css'

export default function Contact() {

    const data = [
        {
            name: "大客户预定",
            wecompany: "https://work.weixin.qq.com/kfid/kfc184c7a854fdb6fba",
            desc:"尊享独立空间，打造您的商务领地。我们为您提供一站式解决方案，满足您对于办公环境的独特需求。从空间规划到家具配置，让您轻松拥有理想的办公环境。现在就预定，开启卓越商务体验！"
        },
        {
            name: "定制装修",
            wecompany: "https://work.weixin.qq.com/kfid/kfccd69b64ce981f7b0",
            desc: "打造专属办公空间，尽显企业文化魅力。我们为您提供个性化定制服务，从色彩搭配到材料选择，让您的办公环境与众不同。专业团队全程跟进，确保装修质量与进度。现在就咨询，为您的企业形象加分！"
        },
        {
            name: "管理输出",
            wecompany: "https://work.weixin.qq.com/kfid/kfc67f8de4fbc9fff14",
            desc: "专业团队助力您的办公管理，让您轻松聚焦核心业务。我们为您提供一站式办公管理服务，包括日常保洁、设备维护、安全管理等，让您享受无忧办公环境。现在就合作，释放您的商务潜能！"
        },
        {
            name: "网络定制",
            wecompany: "https://work.weixin.qq.com/kfid/kfc12e28074b494fd2d",
            desc:"为您提供专业的网络定制服务，满足您独特的办公需求。我们根据您的实际状况，量身打造高效、安全的网络环境，助您轻松实现远程会议、高速文件传输等办公任务。精享优质网络资源，助力企业腾飞，共享办公业务，助您迈向成功之路。立即加入我们，共创美好未来！"
        }
    ]

    return (
        <Row style={{padding:'50px 0'}}>
            <Col span={18} offset={3}>
                <Divider orientation="left" style={{ paddingTop: '80px 40px 0 0',fontSize:'20px' }}>业务合作</Divider>
                <Row gutter={16}>
                    {
                        data.map((item, index) => {
                            return (
                                <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{margin:'10px 0'}}>

                                    <Card title={item.name} extra={<a href={item.wecompany} target="_blank">立即沟通</a>} style={{ width: '100%', minHeight: "230px",background:'#ffffff' }}>
                                        <p>{ item.desc}</p>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Col>
            <Col span={18} offset={3}>
                <Divider orientation="left" style={{ paddingTop: '80px 40px 0 0', fontSize: '20px' }}>客户沟通</Divider>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{margin:'10px 0'}}>

                        <Card title="意见反馈" extra={<a href="https://work.weixin.qq.com/kfid/kfc184c7a854fdb6fba" target='_blank'>立即沟通</a>} style={{ width: '100%', background: '#ffffff' }}>
                            <p>您的声音，我们重视！感谢您选择我们的共享办公服务。为了更好地满足您的需求，我们诚挚地邀请您提供宝贵的意见和建议。您的反馈将成为我们改进服务和提升品质的重要参考。请随时联系我们，让我们共同打造卓越的办公环境！</p>
                        </Card>

                    </Col>
                </Row>
            </Col>
            <Col xs={16} sm={16} md={16} lg={16} xl={24} className='site-1yws7e1'></Col>
        </Row>
    )
}

