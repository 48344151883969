import { useState, useEffect } from 'react'
import { Row, Col, Card } from 'antd'
import './styles/efficiency.css';
export default function Efficiency() {


    const vsdata = [
        {
            name: "租赁",
            desc: "超灵活",
            others: ["租期长，缺乏灵活性，难以适应业务变化。", "固定布局，难以满足多样化需求。", "购买家具和设备，资金压力大。", "独立办公室，缺乏交流机会。","难以调整空间，造成资源浪费。"],
            bcoss: ["短租、长租，灵活选择，适应业务波动", "模块化设计，快速调整空间布局。", "共享家具和设备，降低初期投入。", "开放社区，拓展业务和资源获取。","随时扩展和缩减空间。"]
        },
        {
            name: "装修",
            desc: "超简单",
            others: ["独立装修，费用高昂。", "装修时间较长，影响业务运行。", "单一风格，缺乏灵活性。", "自行维护，难以保证效果。","固定空间，难以扩展。"],
            bcoss: ["共享装修，降低成本。", "快速装修，节约时间。", "多样化风格，满足不同需求。", "专业维护，延长使用寿命。","可扩展空间，适应业务增长。"]
        },
        {
            name: "服务",
            desc: "超周到",
            others: ["有限的服务，难以满足个性化需求。", "质量参差不齐，难以保证效果。", "固定的服务模式，难以调整。", "独立的服务提供，缺乏社区互动。","有限的服务资源，难以满足增长需求。"],
            bcoss: ["多样化的服务，满足各种需求。", "专业的服务，提高工作效率。", "灵活的服务安排，适应业务变化。", "共享服务社区，促进企业间的交流和合作。","可扩展的服务资源，适应业务增长。"]
        },
        {
            name: "成本",
            desc: "超级省",
            others: ["独立租赁，租金成本高。", "独立装修，费用高昂。", "自行维护，成本高。", "独立购置，成本高。","繁琐流程，时间成本高。"],
            bcoss: ["共享空间，降低租金成本。", "共享装修，降低成本。", "专业维护，降低成本。", "共享资源，降低成本。","快速租赁和装修，节约时间成本。"]
        },
        {
            name: "代理服务",
            desc: "超全面",
            others: ["代理服务有限，需自行协调。", "增值服务有限，难以满足需求。", "服务质量参差不齐，难以保证。","自行协调服务，成本较高。"],
            bcoss: ["提供一站式代理服务，方便快捷。", "多样的增值服务，助力企业发展。", "专业团队保障服务质量。","通过规模优势的代理服务降低企业成本。"]
        }
    ]


    return (

        <div style={{marginBottom:"150px"}}>

            <img style={{width:'100%'}} src={ require('../images/vs/banner.png')} />


            <Row gutter={18} justify={'center'}>

                {
                    vsdata.map(function (item, key) {
                        return (
                            <Col lg={18} md={24} sm={24} xs={24}>

                                <Row gutter={24}>
                                    <Col span={24}>
                                        <h2 style={{ textAlign: 'center', fontSize: '40px', color: 'rgb(65, 170, 255)' }}>
                                            <span style={{ color: "#f9bc4e" }}>{item.name}</span>{ item.desc}
                                        </h2>
                                    </Col>
                                    <Col span={24}>
                                        <div style={{ position: 'relative', paddingTop: '50px' }}>
                                            <Row gutter={24} justify={'center'}>
                                                <Col className="vs-box" span={9}>
                                                    <div className="vs-box-title">
                                                        传统办公
                                                    </div>
                                                    {
                                                        item.others.map(function (other, key) {
                                                            return (
                                                                <div className="vs-box-item" key={key}>
                                                                    {other}
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </Col>
                                                <Col span={2} style={{ textAlign: 'center' }}>
                                                    <img style={{ marginTop: '250px', width: '80px' }} src={require('../images/vs/VS@2x.png')} />
                                                </Col>
                                                <Col className="vs-box" span={9}>
                                                    <div style={{ background: '#41AAFF', color: '#fff' }} className="vs-box-title">
                                                        BeingCospace
                                                    </div>
                                                    {
                                                        item.bcoss.map(function (bcos, key) {
                                                            return (
                                                                <div className="vs-box-item vs-box-item-blue" key={key}>
                                                                    {bcos}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                            <Row style={{ position: 'absolute', top: '0', zIndex: '1' }} gutter={24}>
                                                <Col style={{ textAlign: 'center' }} span={24}>
                                                    <img style={{ width: '50%' }} src={require('../images/vs/vs_bg@2x.png')} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                            </Col>
                        )
                    })
                }
                
            </Row>


        </div>
    )

}