import { useState,useEffect } from 'react'
import { Row, Col, Button, Card, Image, Modal, Tag } from 'antd'
import { useNavigate,Link } from 'react-router-dom'
import { WechatOutlined, CaretRightOutlined } from '@ant-design/icons';


import logo from '../images/logo.png'
import videot from '../images/video.mp4'

import coworkData from '../data/cowork.json'


// import Snowfall from 'react-snowfall'
// import img1 from '../images/snow.png'
// import img2 from '../images/snow.png'

// const snowflake1 = document.createElement('img')
// snowflake1.src = img1
// const snowflake2 = document.createElement('img')
// snowflake2.src = img2

// const images = [snowflake1, snowflake2]


export default function Home() {

    window.scrollTo(0, 0)

    const [codata, setData] = useState(coworkData)

    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false);
    function goCowork(){ 
        navigate("/cowork", {replace:true})
    }

    // 随机lottie

    // const [lottiePath, setLottiePath] = useState("");
    // const lotties = [
    //     "https://lottie.host/7dd0d8cd-59ae-42e4-a6fa-2c8f5969fd37/U4iUpUsN8S.json",
    //     "https://assets9.lottiefiles.com/packages/lf20_rsldksfy.json",
    //     "https://lottie.host/91dcb17b-7654-4bc6-ada3-a31c54250fff/6OyeZSE8wW.json",
    //     "https://lottie.host/7dd0d8cd-59ae-42e4-a6fa-2c8f5969fd37/U4iUpUsN8S.json"
    // ]
    // useEffect(() => { 
    //     randomSvg();
    // })
    // function randomSvg() {
    //     const index = Math.floor(Math.random() * 3);
    //     setLottiePath(lotties[index])
    // }

    


    return (
        <>
            
            {/* <Snowfall
                style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                }}
                images={images}
                radius={[5.5, 13.0]}
            /> */}

            <Row className='homeBannerBox' gutter={24} justify="center" align="middle">
                <Col xs={{ span: 24, order: '2' }} sm={{ span: 24, order: '2' }} md={{ span: 24, order: '2' }} lg={{ span: 10, order: 1 }} xl={{ span: 10, order: 1 }}>
                    <div className='homeBanner'>
                        <h1 className='homeBanner_text_1'>
                            BeingCospace
                        </h1>
                        <h1 className='homeBanner_text_2'>
                            <div>
                                是一个空间综合服务商。
                            </div>
                            <svg aria-hidden="true" viewBox="0 0 418 42" class="absolute left-0 top-2/3 h-[0.58em] w-full fill-blue-300/70" preserveAspectRatio="none"><path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path></svg>
                        </h1>
                        <p className='homeBanner_text_3'>
                            Being Cospace (BCos)是由星皓（北京）管理咨询有限公司运营，恒基兆业地产集团旗下的高端共享办公空间品牌。 BCos 立足核心商圈，面向高端商务人士的办公需求，提供可定制的智能办公解决方案。 BCos 提供办公、休闲、运动、餐饮等办公及生活中的必要元素，创造多样化的办公空间，提升办公新体验。
                        </p>

                    </div>
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={10} xl={12}>
                            <Button className='contactButton' target='_blank' href="https://work.weixin.qq.com/kfid/kfc184c7a854fdb6fba" type='primary'><WechatOutlined />联系我们</Button>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={10} xl={12}>
                            <Button onClick={() => setOpenModal(true)} className='contactButton' type="ghost"><CaretRightOutlined />Watch Video</Button>
                        </Col>

                        <Modal

                            centered
                            open={openModal}
                            cancelText={'关闭'}
                            okText={'确认'}
                            destroyOnClose
                            footer={[
                                <Button onClick={() => setOpenModal(false)}>关闭</Button>
                            ]}
                            // onOk={() => setOpenModal(false)}
                            // onCancel={() => setOpenModal(false)}
                            width={1000}
                        >
                            <video width="100%" controls="controls" autoPlay={openModal} loop playsinline src={videot}></video>
                        </Modal>
                    </Row>
                </Col>
                <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 14, order: 2 }} xl={{ span: 10, order: 2 }} style={{ textAlign: 'center' }}>
                    <img style={{width:'100%',maxWidth:"500px"}} src={ require('../images/2024.png')} />
                </Col>
                {/* <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 14, order: 2 }} xl={{ span: 10, order: 2 }} style={{ textAlign: 'center' }}>                   
                    {lottiePath ? <lottie-player src={lottiePath} background="transparent" speed="1" style={{ width: '100%' }} loop autoplay></lottie-player> : ""}
                </Col> */}
            </Row>
            <Row gutter={24} justify="center">
                <Col span={24} >
                    <div className='home_box'>
                        <h1>
                            自营共享办公业务
                        </h1>
                        <p className='home_box_desc'>
                            Being Cospace自营共享办公空间品牌，立足一二线城市的核心商圈，致力为精英商务人群提供高质量的办公服务。
                            依托恒基兆业40多年在地产行业沉淀的技术、资源和服务经验，BCos发布共享办公3.0产品，致力用技术重新定义理想办公。
                        </p>
                        <p>
                            <Button onClick={goCowork} type="ghost" style={{ color: '#c5af84' }}>查看更多</Button>
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='intro_box' justify={'center'} style={{ background: 'rgb(243, 246, 248)',marginBottom:'40px' }}>
                <Col span={24}>
                    <Row gutter={24} justify={'center'} align={'middle'}>
                        {
                            codata.map((item,index) => { 
                              
                                if (index === 3 || index === 4 || index === 5) {
                                        return (
                                            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                                <Link to={"/cowork/detail/" + item.id}>
                                                <Image preview={false} src={require('../images/cowork/' + item.nickname + '/cover.jpg')} />
                                                </Link>
                                                <div className='homePic_desc'>
                                                    {item.name}
                                                    {item.nametag === "房源紧张" ?
                                                        <Tag className='cowork_nametag' color="#cbb791">
                                                            {item.nametag}
                                                        </Tag> :
                                                        <Tag className='cowork_nametag' color="green">{item.nametag}</Tag>
                                                    }
                                                </div>
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <></>
                                        )
                                    }
                                
                            })
                        }
                        
                        
                    </Row>
                </Col>
            </Row>





            {/* <Row gutter={24}>
                <Col span={24}>
                    <div style={{ fontFamily: 'SourceHanSerifSC', background: '#fff', padding: '100px 0', fontSize: '20px', textAlign: 'center' }}>
                        <h1 style={{ color: '#c5af84' }}>
                            快速入驻
                        </h1>
                        <p style={{ padding: '0 300px' }}>
                            专业运营支持
                        </p>
                    </div>
                </Col>
                <Col span={8} className='step_box'>
                    <img src="https://framerusercontent.com/images/pizOKfpzaYrbaaZH0CzxsdZLc.svg" />
                    <div>
                        选择社区
                    </div>
                </Col>
                <Col span={8} className='step_box'>
                    <img src="https://framerusercontent.com/images/L8dIMWh6841HwcMuuwTYH1xUkI.svg" />
                    <div>
                        获取方案
                    </div>
                </Col>
                <Col span={8} className='step_box'>
                    <img src="https://framerusercontent.com/images/4m6sQ1xqtDFl9JSmrp4pa7em28.svg" />
                    <div>
                        办理入驻
                    </div>
                </Col>
            </Row> */}
        </>
    )
}