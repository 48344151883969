
import HanziWriter from "hanzi-writer"
import { useEffect,useState } from "react";
import { notification } from 'antd'
export default function Hanzi() { 
    
    const ceshi = ["我", "好", '忙']
    
    const [api, contextHolder] = notification.useNotification();

    

    useEffect(() => {

        

        var writer = HanziWriter.create('character-target-div', '测', {
            width: 350,
            height: 350,
            showCharacter: false,
            padding: 5
        });
        writer.quiz({
            onMistake: function (strokeData) {
                api['error']({
                    message: '错啦',
                    description: '写错了笔顺，重新试试吧',
                    duration: 0.5
                });          
            },
            onCorrectStroke: function (strokeData) {
                api['success']({
                    message: '正确的一笔',
                    description: '写对了，继续加油',
                    duration: 0.5
                });
            },
            onComplete: function (summaryData) {
                api['open']({
                    message: '完成一个字的练习',
                    description: '写对了，继续加油',
                    duration: 0.8
                });
            }
        });
    }, [  ]);

    return (

        <div>
            {contextHolder}
            <h1>汉字笔顺比赛</h1>
            <div id="character-target-div"></div>
        </div>
        
    )

}