import { Row,Col } from "antd";

export default function Footer() { 
    return (
        <Row>
            <Col span={18} offset={3} style={{textAlign:'center',marginBottom:"20px"}}>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{padding:'10px 0'}}>
                        <img style={{width:'100px'}} src={ require('../../images/gzqrcode.jpg')} />
                        <div>
                            微信公众号
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ padding: '10px 0' }}>
                        <img style={{ width: '100px' }} src={require('../../images/xcx.jpg')} />
                        <div>
                            星会员小程序
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{ padding: '10px 0' }}>
                        <img style={{ width: '100px' }} src={require('../../images/kfqrcode.png')} />
                        <div>
                            客户服务
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col span={24} style={{fontSize:'20px',fontWeight:"bold",textAlign:'center'}}>
                品牌合作：400-818-2666
            </Col>
            <Col span={24} style={{padding:'20px 0'}}>
                <div style={{ textAlign: 'center', fontFamily: 'SourceHanSerifSC' }}>
                    BeingCospace @2023 星皓（北京）管理咨询有限公司 版权所有 京ICP备2020038532号-1
                </div>
            </Col>
        </Row>
        
    )
}