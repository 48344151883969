import '../styles/cowork.css'
import coworkData from '../../data/cowork.json'
import { Row, Col, Image, Button, Tag, Tooltip, Modal } from 'antd'
import { useState } from 'react'
//import { Map, Marker, APILoader } from '@uiw/react-baidu-map';
import logo from '../../images/logo.png'
import { Link } from 'react-router-dom'

//slide
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function Cowork() {
    window.scrollTo(0, 0)
    const citys = ["全部", "北京", "上海", "广州"]
    const [cityIndex,setCityIndex]=useState(0)
    const [data, setData] = useState(coworkData)

    const [isOpenMapModal,setOpenMapModal]= useState(false)

    const hotTag = ["灵活办公", "管家式服务", "高品质装修", "超级会议室","核心CBD"]

    function choseCity(index){ 
        //console.log("heloo")
    }




    return (
        <div>
            <Row>
                <Col span={24}>
                    <Swiper
                        spaceBetween={0}
                        effect={'fade'}
                        navigation={true}
                        pagination={{
                            clickable: true,
                        }}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[EffectFade, Autoplay, Pagination, Navigation]}
                    >
                        <SwiperSlide className='slide_box'>
                            <img src={require('../../images/cowork/banner/8.jpg')} />
                        </SwiperSlide>
                        <SwiperSlide className='slide_box'>
                            <img src={ require('../../images/cowork/banner/1.jpg')} />
                        </SwiperSlide>
                        <SwiperSlide className='slide_box'>
                            <img src={require('../../images/cowork/banner/2.jpg')} />
                        </SwiperSlide>
                        <SwiperSlide className='slide_box'>
                            <img src={require('../../images/cowork/banner/3.jpg')} />
                        </SwiperSlide>
                        <SwiperSlide className='slide_box'>
                            <img src={require('../../images/cowork/banner/4.jpg')} />
                        </SwiperSlide>
                        <SwiperSlide className='slide_box'>
                            <img src={require('../../images/cowork/banner/5.jpg')} />
                        </SwiperSlide>
                        <SwiperSlide className='slide_box'>
                            <img src={require('../../images/cowork/banner/6.jpg')} />
                        </SwiperSlide>
                        <SwiperSlide className='slide_box'>
                            <img src={require('../../images/cowork/banner/7.jpg')} />
                        </SwiperSlide>
                      
                    </Swiper>
                </Col>
            </Row>
            <Row>
                <Col span={24} className='cowork_solgan_box'>
                    <div className='cowork_solgan'>
                        定义理想办公
                    </div>
                </Col>
            </Row>

            <Row justify={'center'} align={'middle'} style={{ background: '#fff' }}>
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                    <Row gutter={10} justify={'center'}>
                        {
                            hotTag.map((item) => {
                                return (
                                    <Col xs={12} sm={12} md={8} lg={8} xl={4} className=''>
                                        <Row align={'middle'} style={{textAlign:'center'}}>
                                            <Col span={5}>
                                                <img style={{width:'100%',height:'40%'}} src={ require("../../images/cowork/maisui_1.png")} />
                                            </Col>
                                            <Col span={14} style={{textAlign:'center'}}>
                                                <h3 style={{padding:'20px 0'}}>
                                                    {item}
                                                </h3>
                                            </Col>
                                            <Col span={5}>
                                                <img style={{ width: '100%', height: '40%' }} src={require("../../images/cowork/maisui_2.png")} />
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            })
                        }
                        
                    </Row>
                </Col>
            </Row>

            <Row justify={'center'} style={{padding:'0 20px'}}>
                {/* <Col span={18} className='cowork_title'>
                    {citys.map((item,index) => { 
                        return (
                            <span className={index === cityIndex ?"activeIndex":""} onClick={choseCity()}>
                                {item}
                            </span>
                        )
                    })}
                </Col> */}
                <Col xs={24} sm={24} md={24} lg={18} xl={18}>

                    {
                        data.map((item) => { 
                            return (
                                <Link to={"/cowork/detail/" + item.id} style={{ color: "#000",float:'left' }}>
                                    <Row gutter={20} className='cowork_box' align={'top'}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{ padding: "0px" }}>
                                        <Row style={{padding:0}}>
                                            <Col span={24}>
                                                <img style={{width:'100%',float:'left'}} preview={false} src={require('../../images/cowork/' + item.nickname + '/cover.jpg')} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={12} style={{padding:'10px 20px'}}>
                                        <Row>
                                            <Col span={24} >
                                                <Link to={ "/cowork/detail/" + item.id}>
                                                    <span className='cowork_name'>{item.name}</span>
                                                    {item.nametag === "房源紧张" ?
                                                        <Tag className='cowork_nametag' color="#cbb791">
                                                        {item.nametag}
                                                    </Tag> :
                                                        <Tag className='cowork_nametag' color="green">{item.nametag}</Tag>
                                                    }
                                                    
                                                </Link>
                                            </Col>
                                            <Col span={24} className=''>
                                                {item.city} {item.location} 

                                            </Col>
                                            <Col span={24}>
                                                <img style={{ width: '20px', position: 'relative', top: '5px', marginLeft: "0px" }} src={require('../../images/ditie.png')} />
                                                {item.subway}
                                            </Col>
                                            <Col span={ 24}>
                                                {
                                                    item.hottag.map((hottag) => { 
                                                        return (
                                                            <Tag bordered={false}>{hottag}</Tag>
                                                     
                                                       )
                                                    })
                                                }
                                            </Col>
                                            <Col span={24} className='cowork_desc'>
                                                {item.description}
                                            </Col>
                                        </Row>
                                    </Col>
                                    </Row>
                                  </Link>
                            )
                        })
                    }
                    
                </Col>
            </Row>


        </div>
    )
}