import { Button } from 'antd'
import infiniteBg from '../images/infinite/bg.png'
export default function Infinite() { 
    return (
        <div className="infinite_bg">
            <img style={{width:'100%',position:"absolute",top:'0',zIndex:'1'}} src={infiniteBg} />
            <div className='infinite_info' style={{paddingTop:"100px"}}>
                
                <h1 className="infinite_title">
                    ️️BeingCospace
                    <span style={{ color: '#c5af84' }}> ♾️ </span>
                    <span className='infinite_title_1'>Infinite</span>
                </h1>
                <h1 className="infinite_title">
                    ️️️挑战未来办公的边界
                </h1>
                <div style={{textAlign:'center'}}>
                    <Button type="primary" size="large" style={{width:"200px",fontFamily:"siyuan"}}>敬请期待</Button>
                </div>

            </div>
        </div>
    )
}