import { Row, Col } from 'antd'
import './styles/smart.css'
export default function Smart() {
    return (
        <div>
            <Row justify={'center'}>
                <Col span={18} style={{fontFamily:'siyuan'}}>
                    办公智能化
                </Col>
                <Col span={8}>
                    <h3 style={{fontFamily:"siyuan"}}>
                        机器人配送
                    </h3>
                </Col>
                <Col span={10}>

                </Col>
            </Row>
        </div>
    )
}