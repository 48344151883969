import React, { useRef, useState } from 'react';
import { Row, Col, Image, Tour, Button } from 'antd'
import type { TourProps } from 'antd';
import modelPic from '../images/customization/WFC-8F.png';
import './styles/customization.css'
import { EllipsisOutlined } from '@ant-design/icons';

export default function Customization() { 

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const [open, setOpen] = useState(false);

    const steps: TourProps['steps'] = [
        {
            title: '电动门禁',
            description: '电动门禁电动门禁电动门禁电动门禁电动门禁电动门禁电动门禁电动门禁',
            target: () => ref1.current,
        },
        {
            title: '智能会议室',
            description: '智能会议室智能会议室智能会议室智能会议室智能会议室智能会议室智能会议室智能会议室智能会议室',
            target: () => ref2.current,
        },
        {
            title: '水吧台设计',
            description: '水吧台设计水吧台设计水吧台设计水吧台设计水吧台设计水吧台设计水吧台设计',
            target: () => ref3.current,
        }, 
        {
            title: '灵活工位设计',
            description: '灵活工位设计灵活工位设计灵活工位设计灵活工位设计灵活工位设计灵活工位设计',
            target: () => ref4.current,
        },
    ];

    return (
<>
            <Row justify={'center'} className='animate__animated animate__fadeInDown'>
                <Col span={20} className='tour_bg_box'>
                    <Image className='tour_bg' preview={false} src={modelPic}></Image>
                    <span className='tour_bg_step_1' ref={ref1} >
                        {/* 大门 */}
                    </span>
                    <span className='tour_bg_step_2' ref={ref2}>
                        {/* 会议室 */}
                    </span>
                    <span className='tour_bg_step_3' ref={ref3}>
                        {/* 水吧 */}
                    </span>
                    <span className='tour_bg_step_4' ref={ref4}>
                        {/* 灵活工位 */}
                    </span>
                </Col>

                    {!open ? (<Button className='tourButton' type="default" onClick={() => setOpen(true)}>
                        快速导览
                    </Button>):(<></>)}
                    
                    <Tour open={open} onClose={() => setOpen(false)} mask={false} type="default" steps={steps} />
               
            </Row>
            <Row justify="center">
                <Col span={24} >
                    <div style={{ fontFamily: 'siyuan', background: '#fff', padding: '50px 0', fontSize: '20px', textAlign: 'center' }}>
                        <h1 style={{ color: '#c5af84' }}>
                            Being Cospace 服务体系全流程方案
                        </h1>
                    </div>
                </Col>
            </Row>
            <Row justify={'center'} align={'middle'} style={{ background: 'rgb(243, 246, 248)', padding: '40px 40px' }}>
                <Col span={24}>
                    <Row gutter={24} justify={'center'} align={'middle'}>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4} className='steps_box'>
                            <div>
                                <h3>
                                    人力管理
                                </h3>
                                <div>
                                    设置项目参数（如：时间、方向、成本、质量等）； 拟定项目交流和执行协议。
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4} className='steps_box'>
                            <div>
                                <h3>
                                    进度控制
                                </h3>
                                <div>
                                    与客户合作进行设计以此取得客户的同意签署；进而在施工前取得政府的批准并进行场地准备工作。
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4} className='steps_box'>
                            <div>
                                <h3>
                                    质量控制
                                </h3>
                                <div>
                                    在完成测试&调试和最后批准时进行实时缺陷的检查；做好场地准备以便客户能进入施工场地。
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4} className='steps_box'>
                            <div>
                                <h3>
                                    安全管理
                                </h3>
                                <div>
                                    如果需要，定期进行自我检查并接受客户的不时检查以保证客户对空间的满意度并提供定期的维护。
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={4} xl={4} className='steps_box'>
                            <div>
                                <h3>
                                    交付管理

                                </h3>
                                <div>
                                    根据在项目初始阶段规定的设计和质量/时间/安全/方向的要求进行场地建设。
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row justify="center">
                <Col span={24} >
                    <div style={{ fontFamily: 'siyuan', background: '#fff', padding: '50px 0', fontSize: '20px', textAlign: 'center' }}>
                        <h1 style={{ color: '#c5af84' }}>
                            【事前管理】需求调研
                        </h1>
                    </div>
                </Col>
            </Row>
        <Row justify="center">
                <Col span={24} >
                    <div style={{ fontFamily: 'siyuan', background: '#fff', padding: '50px 0', fontSize: '20px', textAlign: 'center' }}>
                        <h1 style={{ color: '#c5af84' }}>
                            【事中管理】Being Cospace 服务体系全流程方案
                        </h1>
                    </div>
                </Col>
            </Row>
      
            <Row justify="center">
                <Col span={24} >
                    <div style={{ fontFamily: 'siyuan', background: '#fff', padding: '50px 0', fontSize: '20px', textAlign: 'center' }}>
                        <h1 style={{ color: '#c5af84' }}>
                            【事后管理】项目交付及验收
                        </h1>
                    </div>
                </Col>
            </Row>

            <Row justify="center">
                <Col span={24} >
                    <div style={{ fontFamily: 'siyuan', background: '#fff', padding: '50px 0', fontSize: '20px', textAlign: 'center' }}>
                        <h1 style={{ color: '#c5af84' }}>
                            合作案例
                        </h1>
                    </div>
                </Col>
            </Row>
      
        </>
    )
}